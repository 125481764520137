.locations-list-item{
    width: 48%;
    border-bottom: #4b4b4b 1px solid;
}

.locations-list-item:hover{
    cursor: pointer;
}

.location-list{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    max-width: 460px;
    justify-content: center;
    align-items: center;
}

.location-role-box{
    display: block;
    border: solid #4d4d4d 1px;
    background-color: #373737;
    width: 100%;
    max-width: 458px;
    padding: 24px 0;
}

.spyfall-button{
    
}