.projects-box{
    text-align: left;
    display: flex;
    max-width: 275px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 10px 0;
    background: #1f1f1f;
    border: var(--main-color) solid 1px;
    color: white;
    padding: 4px 0px 0px 0px;
}

.projects-box:hover,
.project-info-container-open:hover{
    cursor: pointer;
    background: #2c4f5a;
    border: #2c4f5a solid 1px;
}

.projects-box-left{
    display: flex;
    align-items: center;
    height: 100%;
    width: 85%;
    font-size: 13px;
}

.projects-box-right{
    display: flex;
    align-items: center;
    height: 100%;
}

.projects-box-icon{
    color: white;
}

.projects-box-info{
    padding: 7px;
}

.project-info-container{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 100vh;
    max-width: 700px;
    max-height: 350px;
    background-color: #1f1f1f;
    border: var(--main-color) solid 1px;
    z-index: 55;
    align-items: center;
    flex-direction: column;
}

.project-info-container-open{
    background-color: #1f1f1f;
    border: var(--main-color) solid 1px;
    width: 100px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-info-background{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 54;
    background-color: black;
    opacity: 0.4;
}

.project-info-container-header{
    font-size: 22px;
    padding: 20px 0 16px 0;
    max-width: 80%;
    display: flex;
    height: 25px;
    align-items: center;
  
}

.project-info-container-content{
    max-width: 80%;
    padding: 16px 0 0 0;
    border-top: var(--main-color) solid 1px;
    text-align: justify;
}

.project-info-container-close{
    position: absolute;
    right:10px;
    top:10px;
}

.project-info-container-close:hover{
    opacity: 0.5;
    cursor: pointer;
}

.projects-box-info:hover{
    /* background-color: #1f1f1f; */
    opacity: 0.5;
}

.projects-box-img{
    width: 25px;
    margin-left: 10px;
}

.projects-box-name{
    height: 25px;
    margin-left: 7px;
    line-height: 21px;
}

@media only screen and (max-width: 960px) {
    .projects-box{
        max-width: 100%;
        height: 70px;
    }
}