.playerlist-player-boxed{
    width: 12%;
    min-width: 90px;
    aspect-ratio: 19/13;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #365a79;
    margin: 3px;
    font-size: 1vw;
    background: #365a79;
    box-shadow: 0 2px 1px -1px #0000002b,0 1px 1px #0000003b,5px 5px 4px #00000085;
  }
  
  .box-players{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .box-story-days{
    width: 56%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 10px 0;
  }
  
  .story-days-boxed{
    width: 8%;
    min-width: 50px;
    aspect-ratio: 7/9;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px #365a79;
    margin: 3px;
    font-size: 12px;
    background: #365a79;
    box-shadow: 0 2px 1px -1px #0000002b,0 1px 1px #0000003b,5px 5px 4px #00000085;
    border-radius: 2px;
  }
  
  .story-days-boxed:hover{
    cursor: pointer;
    background: #2a445c;
    transform: scale(1.04);
  }

  .description-box{
    width: 100%;
    background-color: white;
    color:black;
    max-width: 620px;
    padding: 10px;
    box-shadow: 0 2px 1px -1px #0000002b,0 1px 1px #0000003b,5px 5px 4px #00000085;
    margin-top: 10px;
  }

  @media only screen and (max-width: 960px) {

    .playerlist-player-boxed{
        width: 26% ;
        font-size: 2vw;
      }
      
      .box-players{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
      }
      
      .box-story-days{
        width: 70%;
      }
      
      .story-days-boxed{
        width: 8%;
        min-width: 50px;
        aspect-ratio: 7/9;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 2px #365a79;
        margin: 3px;
        font-size: 12px;
        background: #365a79;
        box-shadow: 0 2px 1px -1px #0000002b,0 1px 1px #0000003b,5px 5px 4px #00000085;
        border-radius: 2px;
      }
    
      .description-box{
        width: 93%;
      }
  
  }
  
  @media only screen and (max-width: 500px) {
    .playerlist-player-boxed{
        width: 44%;
        font-size: 3vw;
      }
  }