.player-list-popup, .inventory-popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.top-left {
    position: absolute;
    top: 10px;
    left: 10px;
}

.top-right {
    position: absolute;
    top: 10px;
    right: 10px;
}
