:root {
--main-color: #00c3ff;
}

html{
  scroll-behavior: smooth;
}

body{
  background: #1f1f1f;
  text-align: left;
  color: #fff;
  font-size: 15px;
  font-family: SFU-UI-Light;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
  text-align: center;
  overflow-x: hidden;

}

.container{
  /* height: 100vh; */
  display: flex;
}

.content{
  width: 100%;
  /* height: 100%; */
  display: flex;
  background-repeat: no-repeat;
  background-image: 'url('+splashImage+')';
}

.left-content{
  width: 55%;
  max-width: 55%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* min-height: 400px; */
}

.right-content{
  width: 45%;
  max-width: 45%;
  height: 100vh;
  display: flex;
  justify-content: center;
  animation-duration: 2s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.right-img{
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  flex-shrink: 0;
  opacity: 0.17;
}

.left-box{
	/* max-height:382px; */
	/* height:382px; */
	width:87%;
	padding-left:11%;
  padding-top: 30px;
	margin:0;
	text-align: left;
}

.main-header{
	font-size: 60px;
	color: var(--main-color);
	font-weight: bold;
	font-family: SFU-UI-Bold;
	padding: 0 0 42px 0px;
	cursor: default;
  line-height: 1;

	animation-duration: 0.5s;
  transition-timing-function: ease-in;
	animation-fill-mode: both;
	animation-name: fadeInFromRightSmall;
}

.main-body{
	padding-left: 32px;
	font-family: SFU-UI-Light;
	text-align: justify;
	font-weight: 300;
	line-height: 1.2;

	animation-duration: 1.2s;
    transition-timing-function: ease-in-out;
	animation-fill-mode: both;
	animation-name: fadeInFromRightSmall;
}

.hideoverflow{
  overflow-y: hidden;
  overflow-x: hidden;
}

.shrink{
	transition: all .2s ease-in-out;
}

.shrink:hover{
	transform: scale(0.9);
}

/* Home Page */
.left-box-frontpage {
  max-height: 382px;
  height: 382px;
  width: 87%;
  padding-left: 13%;
  margin: 0;
  text-align: left;
}

.home-box{
  width: 90%;
  background: #1f1f1f;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 5%;
}

.contact-form-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-text{
  color: white;
  font-size: 15px;
  padding: 12px 20px 12px 0px;
}

.contact-form-upper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;
}
.contact-form-lower{
  width: 100%;
}

.contact-form-header{
  text-align: center;
  font-family: SFU-UI-Bold;
  font-size: 46px;
  color: var(--main-color);
  line-height: 42px;
}

.contact-form-subheader{
  text-align: center;
  font-family: SFU-UI-Light;
  font-size: 16px;
  color: rgb(212, 212, 212);
  max-width: 548px;
  margin-bottom: 55px;
}

.contact-form-email{
  background: none;
  border: none;
  border-bottom: 1px var(--main-color) solid;
  width: 100%;
}

.contact-form-email:focus,
.contact-form-message:focus {
  outline: none;
}

.contact-form-block{
  width: 100%;
  max-width: 256px;
  margin-right: 24px;
}

.contact-form-message{
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  resize: none;
  background: none;
  border: none;
  border-bottom: 1px var(--main-color) solid;
}

.first {
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeInFromRightSmall;
}

.second {
  padding-left: 38px;
  animation-duration: 1s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeInFromRightSmaller;
}

.home-title {
  font-size: 60px;
  font-family: SFU-UI-Bold;
  text-align: left;
  line-height: 1;
  animation-delay: 0.5s;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-box-sub{
  width: 50%;
  height: 100%;
}

.hbox-one{
  max-height: 665px;
}

.hbox-two{
  max-height: 665px;
}

.home-main-img{
  animation-delay: 2.5s;
  border-radius: 8px;
}

.home-subtitle {
  font-size: 13px;
  color: #8d8d8d;
  letter-spacing: 4px;
  font-weight: bold;
  margin: 30px 0;
  animation-delay: 1s;
}

.home-description{
  width: 90%;
  animation-delay: 1.5s;
}

.home-img-square{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 3px solid var(--main-color);
  position: absolute;
  top:0;
  left:0;
  transform: translate(20px,15px);
  transition-duration: 250ms;
}

.home-main-img-container:hover > .home-img-square { transform: translate(13px,8px); }

.intro-fade-in{
  animation-duration: 1s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeInFromBottomSmall;
}

.intro-fade-in-right{
  animation-duration: 1s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: fadeInFromRightSmall;
}

.main-button {
  color: var(--main-color);
  margin: auto;
    margin-top: auto;
  height: 44px;
  width: 160px;
  font-size: 13px;
  letter-spacing: 4px;
  font-family: SFU-UI-Light;
  background-color: transparent;
  background-color: #1f1f1f;
  
  padding: 10px 18px;
  border: 1px solid var(--main-color);
  margin-top: 25px;
  animation-delay: 2s;
}

.main-button:hover{	
	background-color: var(--main-color);
	color: #1f1f1f;
	cursor: pointer;
}

.move-me-main-button{
  color: var(--main-color);
  height: 44px;
  width: 160px;
  font-size: 13px;
  letter-spacing: 4px;
  font-family: SFU-UI-Light;
  background-color: transparent;
  background-color: #1f1f1f;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid var(--main-color);
  position: fixed;

  transition-duration: 450ms;
}

.moveToCornerRight{
  left: 95% !important;
  top: 87% !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;

  transition-duration: 450ms;
  transition-property: visibility, left, top, height, width, border-radius, font-size;
}

.move-me-main-button:hover{	
	background-color: var(--main-color);
	color: #1f1f1f;
	cursor: pointer;
}

/* About Page */
.aboutme-form-container{
  width: 100%;
  max-width: 800px;
  height: 350px;
}

.aboutme-form-header-line{
  border-bottom: 1px #1966aab3 solid;
  display: inline-block;
  width: 47%;
  margin-bottom: 7px;
}

.aboutme-form-header-text{
  font-size: 29px;
  text-align: left;
  font-family: SFU-UI-Regular;
  width: 162px;
  display: inline-block;
  margin-left: -140px;
}

.aboutme-form-content{
  height: 280px;
  width: 100%;
  display: flex;
  margin-top: 43px;
  position: relative;
  font-family: SF-Mono-Light;
  color: #d5d5d5;
}

.aboutme-form-options{
  width: 171px;
  text-align: left;
  border-left: #0b4273b3 solid 3px;
}

.aboutme-form-selections{
  padding: 18px;
}

.aboutme-form-selections:hover{
  cursor: pointer;
  background-color:#0b4273b3;
  color: var(--main-color);
}

.aboutme-form-scroller{
  height: 56px;
  position: absolute;
  border-left: var(--main-color) solid 3px;
  top: 0;
  left: 0;
  transition-duration: 250ms;
}

.aboutme-form-scroller-horizontal{
  width: 131px;
  height: 50px;
  position: absolute;
  z-index: 5;
  border-bottom: var(--main-color) solid 3px;
  top: -3px;
  left: 0;
  transition-duration: 250ms;
  display: none;
}

.aboutme-form-main-header{
  font-size: 22px;
  font-family: SFU-UI-Regular;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: var(--main-color);
}

.aboutme-form-main-choice{
  display: none;
  list-style: none;
  font-size: 13px;
}

.two-rows{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.second-row-bullet{
  padding-top: 8;
}

.aboutme-form-main-choice li{
  margin: 8px 0px 8px 19px;
  line-height: 19px;
  font-size: 15px;
  position: relative;
}

.aboutme-form-main-choice li::before{
  content: '▹';
  color: var(--main-color);
  position: absolute;
  left: -22px;
}

.selectedabouttab{
  background-color:#0b4273b3;
  color: var(--main-color);
}

/* .selectedabouttab::before{
  content:'';
  height: 56px;
  transform: translate(-21px,-18px);
  position: absolute;
  border-left: var(--main-color) solid 3px;
} */

.aboutme-form-main{
  margin-left: 30px;
  width: 69%;
  text-align: left;
}


.nonweb{
	visibility: hidden;
	display: none;
}

/* Contacts Page */

.contact-box-img{
	max-width: 110px;
	max-height: 110px;
	margin: 0;
	cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stand-input{
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  max-width: 80%;
  width: 250px;
  font-size: 1em;
  margin: 10px;
}

.input-button {
  color: var(--main-color);
  margin: 10px;
  height: 44px;
  width: 160px;
  font-size: 13px;
  letter-spacing: 4px;
  font-family: SFU-UI-Light;
  background-color: transparent;
  background-color: #1f1f1f;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid var(--main-color);
  animation-delay: 2s;
}

.input-button:hover{	
	background-color: var(--main-color);
	color: #1f1f1f;
	cursor: pointer;
}

.rules-button{
  text-decoration: none !important;
  color: var(--main-color);
  margin: 10px;
  height: 17px;
  aspect-ratio: 1;
  border-radius: 50px;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: SFU-UI-Light;
  background-color: transparent;
  background-color: #1f1f1f;
  text-decoration: none;
  padding: 10px 30px;
  border: 1px solid var(--main-color);
  animation-delay: 2s;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.lobby-button{
  border: 1px solid #fff;
  background-color: #fff;
  margin: 0px;
  height: 21px;
  width: 59px;
  font-size: 10px;
  letter-spacing: 1px;
  padding: 0px 0px;
  color: black;
  margin-left: 26px;
}

.lobby-button:hover{
  border: 1px solid #fff;
  background-color: #1f1f1f;
  color: #fff;
}

.set-clickable{
  pointer-events: all !important;
	cursor: pointer;
}

.rules-button:hover{	
	background-color: var(--main-color);
	color: #1f1f1f;
	cursor: pointer;
}

.hover-red{
	color: #932020;
  border: 1px solid #932020;
}

.hover-red:hover{
  background-color: #932020;
	color: #1f1f1f;
	cursor: pointer;
}

.hover-blue{
	color: #1717db;
  border: 1px solid #20207e;
}

.hover-blue:hover{
  background-color: #20207e;
	color: #1f1f1f;
	cursor: pointer;
}

.join-room-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.join-game-error, .start-game-error{
  color: red;
  padding: 10px;
  opacity: 0;
  transition-duration: 300ms;
}

.join-prompt{
  max-width: 600px;
  margin-top: 58px;
  margin-bottom: 58px;
}

.playerlist-vert{
  margin: 14px 0px;
}

.playerlist-player{
  margin: 4px 7px;
  transform: translateY(-5px);
}

.playerlist-player:nth-child(odd) {
  transform: translateY(5px);
}

.room-name{
  font-size: 20px;
  margin-bottom: 31px;
}

.show-opacity{
  opacity: 1;
}

.flex-center{
  display: flex;
  justify-content: center;
}

.flex-center-column{
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center
}

@media only screen and (max-width: 960px) {
  body, html {
    overflow-y: visible;
  }

  .hideoverflow{
    overflow-y: visible;
  }

  .container{
    flex-direction: column;
    height: 100%;
  }

  .content{
    
    background-size: 600px auto;
  }

  .left-box-frontpage{
		height:100%;
		padding:90px 0 5px 0;
		margin:auto;
		text-align:center;
	}

  .second{
		padding-left: 0;
	}

  .nonweb{
		visibility: visible;
		display: block;
	}

  .right-content{
    display: none;
  }

  .left-content{
    width:88%;
    max-width: 100%;
    text-align: justify;
    display: block;
    margin: auto;
  }

  .left-box{
		height:100%;
		max-height: 100%;
		padding:58px 0 5px 0;
		margin:auto;
	}

  .main-body{
    padding-left: 0;
  }

  .main-space-even{
    flex-direction: column;
    align-items: center;
    /* height: 401px; */
    justify-content: space-evenly;
    width: unset !important;
  }

  .contact-form-upper{
    flex-direction: column;
    margin-bottom: 2px;
  }

  .contact-form-block{
    margin-bottom: 28px;
  }

  .contact-form-header{
    font-size: 36px;
  }

  .hbox-one{
    height: 85vh;
  }

  .hbox-two{
    height: 85vh;
  }

  .just-for-mobile{
    display: block !important;
  }

  .just-for-web{
    display: none !important;
  }

}

@media only screen and (max-width: 550px) {

  

  .projects-body{
    flex-direction: column;
    justify-content: center;	
  }

  .project-button{
    width: 97% !important;
    height: auto !important;
    margin: 0px 5px 30px 5px;
    flex-shrink: 0;
    padding: 25px 0px;
  }

  .websites-body{
    flex-direction: row;
    justify-content: center;	
    padding-top: 20px;
  }

  .websites-button{
    margin: 0px 5px 30px 5px;
    flex-shrink: 0;
    padding: 25px 0px;
    width: 250px !important;
    height: 250px !important;
  }

  .apps-body{
    flex-direction: column;
    justify-content: center;	
  }

  .apps-button{
    width: 97% !important;
    height: auto !important;
    margin: 0px 5px 30px 5px;
    flex-shrink: 0;
    padding: 25px 0px;
  }

  .about-body{
    text-align: left;
  }

  .info-box-left{
    width:100%;
    height: auto;
  }

  .info-box-right{
    width:100%;
    height: auto;
  }

  .info-box-image{
    padding-left: 0px;
    padding-top: 30px;
    padding-bottom: 88px;
  }

  .info-box{
    height: auto;	
  }

  .info-box-continue{
      bottom: 21px;
      left: calc(50% - (100%/2));
  }
  
  .content{
    height: fit-content;
    margin-bottom: 70px;
  }

  .home-box{
    display: block;
  }

  .home-box-sub{
    width: 100%;
    height: auto;
    padding: 37px 0 10px 0;
  }
  .home-main-img{
    max-width: 200px !important;
    max-height: 200px !important;
  }

  .home-img-square{
    border: 2px solid var(--main-color);
    transform: translate(17px,12px);
  }

  .aboutme-form-scroller{
    display: none;
  }

  .aboutme-form-content{
    flex-direction: column;
    margin-top: 32px;
  }

  .aboutme-form-options{
    display: flex;
    width: 100%;
    height: 50px;
    position: absolute;
    overflow-x: auto;
    border-bottom: #0b4273b3 solid 2px;
    border-left: none;
    white-space: nowrap;
  }

  .aboutme-form-header-text{
    margin-left: 0;
  }

  .aboutme-form-main{
    margin-left: 0;
    width: 100%;
  }

  .aboutme-form-header-text{
    margin-top: 30px;
    width: 146px;
  }

  .aboutme-form-selections{
    padding: 4px 18px 4px 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 42px;
    font-size: 13px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .aboutme-form-main-header{
    margin-top: 87px;
  }

  .hbox-two{
    height: 83vh;
  }

  .second-row-bullet{
    padding-top: 0;
  }

  .two-rows{
    columns: 1;
  }

  .aboutme-form-scroller-horizontal{
    display: block;
  }
  
}




/* Animations and Font */
.rotate180{
	animation-duration: 0.2s;
	transition-timing-function: ease-in-out;
	animation-fill-mode: both;
	animation-name: rotate180;
}

@keyframes rotate180 {
	0% {
	  
	}
  
	100% {
	  transform: none;
	  -ms-transform: rotate(180deg); /* IE 9 */
	  transform: rotate(180deg);
	}
} 

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
    }
  
    @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    
    100% {
      opacity: 0;
    }
    }
    

    @keyframes fadeInFromRightSmall {
      0% {
        opacity: 0;
        transform: translate3d(2%, 0, 0);
        -ms-transform:translate3d(2%, 0, 0);
      }
      
      100% {
        opacity: 1;
        transform: none;
        -ms-transform:none;
      }
      } 
    
      @keyframes fadeInFromRightSmaller {
      0% {
        opacity: 0;
        transform: translate3d(3%, 0, 0);
        -ms-transform: translate3d(3%, 0, 0);
      }
      
      100% {
        opacity: 1;
        transform: none;
        -ms-transform:none;
      }
      } 
    
      @keyframes fadeInFromBottomSmall {
      0% {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
        -ms-transform: translate3d(0, 15%, 0);
      }
      
      100% {
        opacity: 1;
        transform: none;
        -ms-transform: none;
      }
      } 
    
      @keyframes fadeInFromBottom {
      0% {
        opacity: 0;
        -ms-transform: translate3d(0, 30%, 0);
        transform: translate3d(0, 30%, 0);
      }
      
      100% {
        opacity: 1;
        transform: none;
        -ms-transform: none;
      }
      } 

@font-face {
	font-family: CODE-bold;
	src: url(../css/fonts/CODE\ Bold.otf);
}

@font-face {
	font-family: CODE-light;
	src: url(../css/fonts/CODE\ Light.otf);
}
	
@font-face {
	font-family: Big-Noodle-Oblique;
	src: url(../css/fonts/big_noodle_titling_oblique.ttf);
}

@font-face {
	font-family: Big-Noodle;
	src: url(../css/fonts/big_noodle_titling.ttf);
}

@font-face {
	font-family: Evogria;
	src: url(../css/fonts/Evogria.otf);
}

@font-face {
	font-family: Exo-Bold;
	src: url(../css/fonts/Exo-Bold.otf);
}

@font-face {
	font-family: Exo-Light;
	src: url(../css/fonts/Exo-Light.otf);
}

@font-face {
	font-family: SFU-UI-Regular;
	src: url(../css/fonts/SFUIText-Regular.woff);
}

@font-face {
	font-family: SFU-UI-Bold;
	src: url(../css/fonts/SFUIText-Bold.woff);
}

@font-face {
	font-family: SFU-UI-Light;
	src: url(../css/fonts/SFUIText-Light.woff);
}

@font-face {
	font-family: SF-Mono-Regular;
	src: url(../css/fonts/SFMonoRegular.woff);
}

@font-face {
	font-family: SF-Mono-Light;
	src: url(../css/fonts/SFMonoLight.woff);
}

@font-face {
	font-family: SF-Mono-Bold;
	src: url(../css/fonts/SFMonoBold.woff);
}

