.header{
    width: 68px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #181818;
    z-index: 50;
	position:fixed;
	height: 100vh;
}

.fakeheader{
    width: 68px;
	height: 100vh;
}

.icon{
    background-color: #000;
    display: flex;
    justify-content: center;
    cursor: pointer;
    min-height: 68px;
    min-width: 68px;
}

.menubutton{
	position: relative;
	cursor: default;
	background-color: transparent;
	display: none;
}

.mobileonly{
	visibility: hidden;
	display: none;
}

.logo-img {
    width: 49px;
    height: 49px;
    margin: auto;
	cursor: pointer;
}

.nav-bar-active{
	color: red;
}

.navbar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 68px;
}

.nav-icon{
	font-size:29px;
	font-weight: normal;
	/* color: #8d8d8d; */
	cursor: pointer;
	opacity: 1;
}

.nav-text{
	position: absolute;
	color: var(--main-color) !important;
	top:50%;
	margin-top: -35px;
	left:0;
	cursor: pointer;
	width: 100%;
	padding: 9px 0 7px 0;
	opacity: 0;
	z-index: 2;
}

.navbox{
    height: 61px;
    width: 68px;
    font-size: 9px;
    padding: 5px 0 5px 0;
    color: var(--main-color);
    letter-spacing: 2px;
    font-weight: bold;
    position: relative;

	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.navlinks{
	display: none;
}

.navmain{
	display: flex;
}

.widthzero{
    width:100%;
}

.widthonehundred{
    width:100%;
}

.nav-text:hover{ transition: opacity 0.4s linear; opacity:1; }
.nav-text:hover + .nav-icon { transition: opacity 0.4s linear; opacity:0;  }

.nav-icon{ transition: opacity 0.2s linear;	}
.nav-text{ transition: opacity 0.2s linear; opacity:0; }



@media only screen and (max-width: 960px) {

	.container{
        width: 100%;
        /* position: fixed; */
        overflow-x: hidden;
	}
	.header{
		width: 100%;
		height: 68px;
		flex-shrink: 0;
		text-align: center;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	.fakeheader {
		width: 0;
		height: 0;
	}

	.navbar{
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 68px;
		padding-top: 0px;
		padding-bottom: 0px;
	
	}
	
	.navbox{
		height: 40px;
		padding: 17px 0 5px 0;
	}	

	.nav-text{
		margin-top: -11px;
		padding-top: 0px;
	}
}

@media only screen and (max-width: 550px) {
	.header{
		justify-content: space-between;
		position: relative;
	}

	.menubutton{
		display: flex;
	}

	.navbar{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 0;
		opacity: 0;
		height: 68px;
		padding-top: 0px;
		padding-bottom: 0px;
		position: absolute;
		top:68px;
		right:-100px;
		background-color: #181818;
		transition: width 0.2s linear;
		z-index:10;
	}
	
	.navbox{
		height: 40px;
		padding: 15px 0 5px 0;
	}	

	.nav-text{
		margin-top: -11px;
	}

	.navbar-menu{
		display: block;
	}

	.navbar-menu{
		display: block;
	}

	.main-header{
		font-size: 36px;
		text-align: center;
	}

	.mobileonly{
		visibility: visible;
		display: block;
	}

	.webonly{
		visibility: hidden;
		display: none;
	}

    .widthzero{
        width:0;
        right: -100px;
    }

    .widthonehundred{
        width:100%;
        right:0;
    }

    .opacitynone{
        opacity:1;
    }

	
}